import { v4 as uuid } from 'uuid';
import {
  ALIGNMENT_MAP,
  NEXT_SEAT_SIDES,
  OPPOSITE_SEAT_SIDES,
  PREVIOUS_SEAT_SIDES,
  SIDE_KEYWORD,
} from '../constants';
import {
  getAlignKeyword,
  getNextValidLocalSide,
  depthFirstTraverse,
  swapSideKey,
} from '../utils';
import { convertSideEvent } from '../events';

import Seat from './Seat';
import Side from './Side';
import RollarmSide from './RollarmSide';
import WedgeSeat from './WedgeSeat';
import DeepSide from './DeepSide';
import DoubleSidedSide from './DoubleSidedSide';
import StandardOutdoorSeat from './StandardOutdoorSeat';
import StandardOutdoorSide from './StandardOutdoorSite';
import DeepOutdoorSide from './DeepOutdoorSite';
import AngledSide from './AngledSide';
import DeepAngledSide from './DeepAngledSide';
import Anytable from './Anytable';
import { status } from '../../status';

export const ItemMap = {
  seat: {
    standard: Seat,
    wedge: WedgeSeat,
    standard_outdoor: StandardOutdoorSeat,
  },
  side: {
    standard: Side,
    deep: DeepSide,
    doubleSided: DoubleSidedSide,
    rollArm: RollarmSide,
    standard_outdoor: StandardOutdoorSide,
    deep_outdoor: DeepOutdoorSide,
    angled: AngledSide,
    deepAngled: DeepAngledSide,
  },
  anytable: {
    standard: Anytable,
    '2shelves': Anytable,
    shelfDrawer: Anytable,
  },
};

export default class Island {
  constructor(threekitApi, items, options = {}) {
    this._id = options.id || uuid();
    this._threekitApi = threekitApi;
    this._firstItem = null;
    this._lastItem = null;
    this._items = [];

    this.layout = null;

    this._setItems(items);
  }

  // find the seat item that local at top/left in the world space
  _setFirstItem = () => {
    const seatItems = this.getSeatItems();

    if (!seatItems.length) return null;

    let firstItem = seatItems[0];
    let firstTLTrans = firstItem.getVertexWorldTranslation('topLeft');

    // first find the item which its topLeft vertex has the smallest z and x val
    for (let idx = 1; idx < seatItems.length; ++idx) {
      const curItem = seatItems[idx];
      const curTLTrans = curItem.getVertexWorldTranslation('topLeft');

      if (
        curTLTrans.z < firstTLTrans.z ||
        (curTLTrans.z === firstTLTrans.z && curTLTrans.x < firstTLTrans.x)
      ) {
        firstItem = curItem;
        firstTLTrans = curTLTrans;
      }
    }
    // the logic we use will check z coordinate first, but there are case that
    // an item has smallest z coordinate, but it still has item coonect to its left
    // second check if there are still seat connect to its left
    let leftItem = firstItem.getConnectedItemWithWorldSide('left');
    while (leftItem && leftItem._type === 'seat') {
      firstItem = leftItem;
      leftItem = firstItem.getConnectedItemWithWorldSide('left');
    }

    this._firstItem = firstItem;
  };

  _setLastItem = () => {
    let lastItem = this._firstItem;

    let rightItem = lastItem?.getConnectedItemWithWorldSide('right');
    while (rightItem) {
      if (rightItem._type === 'seat') lastItem = rightItem;

      rightItem = rightItem.getConnectedItemWithWorldSide('right');
    }

    this._lastItem = lastItem;
  };

  _setItems = (items) => {
    this._items = items || [];
    this._setFirstItem();
    this._setLastItem();

    this._items.forEach((item) => {
      item.island = this._id;
    });
  };

  _getItemsByType = (type, key) =>
    this._items.filter(
      (item) => item._type === type && (key ? item._key === key : true)
    );

  // this will connect the specific item to the connector, and update all item connect to this specific item as well
  _connectRecersive = (root, connector) => {
    const visited = new Set();

    const connectHelper = (root, connector) => {
      if (visited.has(root)) return;
      visited.add(root);

      root._connectTo(connector);

      const rootConnectLocalSide = SIDE_KEYWORD.find(
        (side) => root[side] === connector.target
      );

      SIDE_KEYWORD.forEach((side) => {
        if (side === rootConnectLocalSide || !root[side]) return;

        connectHelper(root[side], {
          target: root,
          targetLocalSide: side,
          alignment: root[getAlignKeyword(side)],
          localSide: SIDE_KEYWORD.find((s) => root[side][s] === root),
        });
      });
    };
    connectHelper(root, connector);
  };

  // given an item return all the valid connector where it can go
  getConnector = (item, options = {}) => {
    const validationItems = options.validationItems || this._items;
    const insertAtEnd = !!options.insertAtEnd; // render plus sign with insert mode at left of the firstItem and right of lastItem even if that side already has a side connected
    const autoRotate = !!options.autoRotate; // automatically apply the rotation based on target item

    let connectorItems = this.getSeatItems().concat(
      this._getItemsByType('anytable')
    );
    const { rotation: initRotation } = item;
    const isAnytable = item._type === 'anytable';

    if (item._type === 'seat') {
      connectorItems = connectorItems.concat(
        this._getItemsByType('side', 'doubleSided')
      );
    }

    const connections = {};

    const allConnectors = connectorItems
      .map((targetItem) => {
        targetItem.connectors = {};
        // Validate if items can be connected
        if (!item.validateConnection(targetItem)) return [];

        // Connectors on each side of target item
        const sideConnectors = SIDE_KEYWORD.map((localSide) => {
          if (!targetItem.hasOwnProperty(localSide)) {
            return [];
          }
          const { rotation: targetRotation } = targetItem;
          if (autoRotate) item.setRotation(targetRotation);

          const worldSide = targetItem.getWorldSide(localSide);
          // handle the insert connector
          if (
            targetItem[localSide] !== null &&
            item.validateConnection(targetItem[localSide])
          ) {
            if (
              insertAtEnd &&
              item._type === 'seat' &&
              targetItem[localSide]._type === 'side' &&
              targetItem[localSide]._key !== 'doubleSided' &&
              ((targetItem === this._firstItem && worldSide === 'left') ||
                (targetItem === this._lastItem && worldSide === 'right'))
            ) {
              return [
                {
                  target: targetItem,
                  targetLocalSide: localSide,
                  alignment: worldSide === 'left' ? 'bottom' : 'top',
                  insert: true,
                },
              ];
            } else if (isAnytable) {
              if (
                targetItem._type === 'seat' &&
                targetItem[localSide]._type === 'seat' &&
                !(
                  connections[targetItem._id] &&
                  connections[targetItem._id] === targetItem[localSide]._id
                )
              ) {
                // insert between seats
                const connector = {
                  target: targetItem,
                  targetLocalSide: localSide,
                  alignment: 'bottom',
                  insert: true,
                  anytable: true,
                };

                connections[targetItem[localSide]._id] = targetItem._id;
                return [connector];
              } else if (
                targetItem._type === 'anytable' &&
                targetItem[localSide]._type === 'seat'
              ) {
                // insert between anytable & seat
                const connector = {
                  target: targetItem,
                  targetLocalSide: localSide,
                  alignment: 'bottom',
                  insert: true,
                  anytable: true,
                };

                return [connector];
              } else if (
                targetItem._type === 'seat' &&
                targetItem[localSide]._type === 'side' &&
                targetItem[localSide].style === 'arm'
              ) {
                // handle append arm side
                const sideConnectionSide = Object.keys(ALIGNMENT_MAP).find(
                  (s) => targetItem[localSide][s]?._id === targetItem._id
                );
                const oppositeSide = OPPOSITE_SEAT_SIDES[sideConnectionSide];
                if (!targetItem[localSide][oppositeSide]) {
                  const connector = {
                    target: targetItem[localSide],
                    targetLocalSide: oppositeSide,
                    alignment: 'bottom',
                    anytable: true,
                  };

                  return [connector];
                }
              }
            }

            return [];
          }

          const targetSideDimension = targetItem.getLocalSideDimension(
            localSide
          );

          const itemWorldSide = OPPOSITE_SEAT_SIDES[worldSide];
          const itemLocalSide =
            item._type === 'side' ? 'bottom' : item.getLocalSide(itemWorldSide);

          const itemDimension =
            item._type === 'side'
              ? item.width
              : item.getLocalSideDimension(itemLocalSide);

          // deep side can not attach to the side longer than its width
          if (
            item._key.indexOf('deep') !== -1 &&
            targetSideDimension !== itemDimension
          ) {
            return [];
          }
          // Only deep side should be added to a deep seat
          if (
            item._key.indexOf('deep') === -1 &&
            targetItem.top?._type === 'seat' &&
            targetItem.bottom?._type === 'seat' &&
            /left|right/.test(localSide)
          ) {
            return [];
          }

          const res = [];

          let defaultAlignment;

          switch (worldSide) {
            case 'left':
              defaultAlignment =
                item._type === 'side' && this.angleToX < 180 ? 'top' : 'bottom';
              break;
            case 'top':
              defaultAlignment =
                targetItem === this._firstItem ? 'bottom' : 'top';
              break;
            case 'right':
              defaultAlignment =
                item._type === 'side' && this.angleToX < 180 ? 'bottom' : 'top';
              break;
            default:
              defaultAlignment =
                targetItem === this._firstItem || item._type === 'side'
                  ? 'bottom'
                  : 'top';
          }

          if (isAnytable) {
            if (/top|bottom/.test(localSide)) {
              res.push({
                target: targetItem,
                targetLocalSide: localSide,
                alignment: defaultAlignment,
                localSide: itemLocalSide,
              });
            } else if (
              targetItem[NEXT_SEAT_SIDES[localSide]] &&
              !targetItem[PREVIOUS_SEAT_SIDES[localSide]]
            ) {
              res.push({
                target: targetItem,
                targetLocalSide: localSide,
                alignment: 'top',
                localSide: 'bottom',
              });
            } else {
              res.push({
                target: targetItem,
                targetLocalSide: localSide,
                alignment: 'bottom',
                localSide: 'bottom',
              });
            }
          } else {
            res.push({
              target: targetItem,
              targetLocalSide: localSide,
              alignment: defaultAlignment,
              localSide: itemLocalSide,
            });

            if (itemDimension !== targetSideDimension) {
              res.push({
                target: targetItem,
                targetLocalSide: localSide,
                alignment: ALIGNMENT_MAP[defaultAlignment],
                localSide: itemLocalSide,
              });
            }
          }

          return res.filter((connector) => {
            try {
              if (connector.insert) return true;
              item._connectTo(connector);
              const valid = validationItems
                .filter((validationItem) => validationItem !== targetItem)
                .every((existItem) => !existItem.intersectWith(item));
              item._disconnect();
              item.setRotation(autoRotate ? targetRotation : initRotation);
              return valid;
            } catch (e) {
              item._disconnect();
              item.setRotation(autoRotate ? targetRotation : initRotation);
              return false;
            }
          });
        });
        return sideConnectors.filter((connectors, index) => {
          if (connectors.length) {
            targetItem.connectors[SIDE_KEYWORD[index]] = connectors;
            return true;
          }
          return false;
        });
      })
      .filter((res) => res.length);
    item.setRotation(initRotation);
    return allConnectors;
  };

  // the addItem will make update the firstItem if needed (new item connect to the world left of the current first item)
  addItem = async (item, connector) => {
    this.pushItem(item, connector);
  };

  // push existing item to the island, this will not init the item
  pushItem = (item, connector) => {
    this._items.push(item);
    item._connectTo(connector);
    if (item._type === 'seat' && connector.target === this._firstItem) {
      this._setFirstItem();
    }

    this._setLastItem();
  };

  // difference behavious will be applyed, based on the remaining layout, when delete an item
  // after disconnect the item, it will start traverse from all its connected items to to find the union between the remaining items
  // 1. If item has no seat connection, it will delete the item and all connect items
  // 2. if the item only has one seat connection, it will delete the item, connect any item on the opposite side (left <-> right, top <-> bottom) to the item on seat connection side, and delete all items on the vertical side
  // 3. if the item has two seat connections
  //   a. If after deletion, the remaining item are still connected (means a circle exist before), we simply delete the item and all vertical side connection.
  //   b. If two seat connections are along the opposite side, we delete the item and its vertical direction connected items, and re-connect the two items along horizontal size
  //   c. If two seat connections are in vertical direction. We delete the item and all non-seat connection, and split the island into two
  // 4. If the item has more than two seat connections, we will just delete the item itself(along with all non-seat connection), and split and update island when needed

  deleteItem = (item, update = true) => {
    if (item.island !== this._id) return;

    let deletedNodes = [item];
    const isolateSets = [];
    let snapTranslation = null;

    const existConnections = item.getConnections();
    const existSeatConnections = existConnections.filter(
      (connection) =>
        connection.target._type === 'seat' ||
        connection.target._type === 'anytable'
    );

    const connections = [];

    if (existSeatConnections.length === 0 && item._type === 'seat') {
      deletedNodes = deletedNodes.concat(
        existConnections.map(({ target }) => target)
      );
    } else if (
      existSeatConnections.length === 1 ||
      (existSeatConnections.length === 2 &&
        existSeatConnections[0].localSide ===
          OPPOSITE_SEAT_SIDES[existSeatConnections[1].localSide])
    ) {
      const thisSide = existSeatConnections[0].localSide;
      const oppositeSide = OPPOSITE_SEAT_SIDES[thisSide];
      const oppositeItem = item[oppositeSide];

      SIDE_KEYWORD.forEach((side) => {
        if (side !== thisSide && side !== oppositeSide && item[side]) {
          deletedNodes.push(item[side]);
        }
      });
      // connect the opposite item
      if (oppositeItem) {
        const initTranslation = oppositeItem.translation;
        const target = item[thisSide];
        const targetLocalSide = SIDE_KEYWORD.find(
          (side) => item[thisSide][side] === item
        );
        const oppositeItemConnectSide = SIDE_KEYWORD.find(
          (side) => oppositeItem[side] === item
        );
        const connector = {
          target,
          targetLocalSide,
          alignment: target[getAlignKeyword(targetLocalSide)],
          localSide: oppositeItemConnectSide,
        };
        if (oppositeItem._type === 'side') {
          connector.alignment = item[getAlignKeyword(oppositeSide)];
        } else if (
          update &&
          item._type === 'side' &&
          target._type === 'anytable' &&
          oppositeItem._type === 'seat' &&
          (oppositeItemConnectSide === 'top' ||
            oppositeItemConnectSide === 'bottom')
        ) {
          ['left', 'right'].map(async (side) => {
            if (oppositeItem[side]?._key.indexOf('deep') === -1) {
              // replace a standard side with a deep version
              const deleteItem = oppositeItem[side];
              const deleteItemId = deleteItem.getInstanceId();
              const deleteItemKey = deleteItem._key;

              const key = swapSideKey(deleteItemKey);

              const { style } = deleteItem;

              this.deleteItem(deleteItem);
              const ProperSide = ItemMap.side[key];
              const sideInstance = new ProperSide(this._threekitApi);
              await sideInstance.init();
              const connector = {
                target: oppositeItem,
                targetLocalSide: side,
                alignment: oppositeItem[getAlignKeyword(side)],
                localSide: 'bottom',
              };
              this.addItem(sideInstance, connector);
              sideInstance.style = style;
              convertSideEvent(
                deleteItemId,
                deleteItemKey,
                sideInstance.getInstanceId(),
                sideInstance._key
              );
            }
          });
        }
        const connectedTranslation = oppositeItem.translation;

        snapTranslation = {
          x: connectedTranslation.x - initTranslation.x,
          y: 0,
          z: connectedTranslation.z - initTranslation.z,
        };

        if (item._type === 'anytable' && oppositeItem._type === 'side') {
          const c = {
            target: oppositeItem,
            targetLocalSide: 'top',
            localSide: thisSide,
          };
          connections.push([target, c]);
        } else connections.push([oppositeItem, connector]);
      }
    } else if (item._type === 'seat') {
      const sideConnection = SIDE_KEYWORD.map((side) => {
        if (item[side] && item[side]._type === 'side') return item[side];
      }).filter((ite) => !!ite);

      deletedNodes = deletedNodes.concat(sideConnection);

      SIDE_KEYWORD.filter(
        (side) => !!item[side] && item[side]._type === 'seat'
      ).forEach((side) => {
        for (const set of isolateSets) {
          if (set.has(item[side])) {
            return;
          }
        }
        const traverseSet = depthFirstTraverse(item[side], new Set([item]));

        isolateSets.push(traverseSet);
      });

      isolateSets.forEach((set) => set.delete(item));
    }

    const resultIsland = { ...this };

    if (update) {
      try {
        deletedNodes.forEach((deleteItem) => {
          this.popItem(deleteItem);
          const instanceId = deleteItem.getInstanceId();
          this._threekitApi.scene.deleteNode(instanceId);
        });
        connections.forEach((connection) =>
          this._connectRecersive(...connection)
        );
      } catch (e) {
        console.error(e);
        // the item does not initialed
      }
    } else {
      const filter = new Set();
      deletedNodes.forEach((n) => filter.add(n._id));
      const items = [];
      const itemMap = {};
      this._items.forEach((item) => {
        if (!filter.has(item._id)) {
          const copy = { ...item };
          SIDE_KEYWORD.forEach((side) => {
            if (copy[side] && filter.has(copy[side]._id)) {
              copy[side] = null;
            }
          });
          itemMap[copy._id] = copy;
          items.push(copy);
        }
      });
      connections.forEach((connection) => {
        const [oppositeItem, connector] = connection;
        itemMap[oppositeItem._id][connector.localSide] =
          itemMap[connector.target._id];
        itemMap[connector.target._id][connector.targetLocalSide] =
          itemMap[oppositeItem._id];
      });
      items.forEach((item) => {
        SIDE_KEYWORD.forEach((side) => {
          if (item[side] && itemMap[item[side]._id]) {
            item[side] = itemMap[item[side]._id];
          }
        });
      });
      resultIsland._items = items;
    }

    return { isolateSets, snapTranslation, deletedNodes, resultIsland };
  };

  popItem = (item) => {
    const itemIdx = this._items.indexOf(item);
    if (itemIdx === -1) throw new Error('Delete Item Error, item not exist!');

    this._items.splice(itemIdx, 1);
    item._disconnect();
    if (item === this._firstItem) {
      this._setFirstItem();
    }
    if (item === this._lastItem) {
      this._setLastItem();
    }
    return item;
  };

  getSeatItems = () => this._getItemsByType('seat');

  getSideItems = () => this._getItemsByType('side');

  getItems = () => [...this._items];

  getItemByInstanceId = (instanceId) =>
    this._items.find((item) => item.getInstanceId() === instanceId);

  toJson = () => {
    const itemJsonArr = this._items.map((item) => item.toJsonObj());
    return JSON.stringify({
      _items: itemJsonArr,
      _firstItem: this._firstItem && this._firstItem.getInstanceId(),
      _id: this._id,
    });
  };

  fromJson = (json) => {
    const obj = JSON.parse(json);
    // getValueProperties(this).forEach((val) => {
    //   if (!obj.hasOwnProperty(val)) throw new Error('Json format is invalid!');
    // });

    const { _firstItem, _items, _id } = obj;
    const { _threekitApi } = this;

    if (!_firstItem) return;

    // construct the graph with dfs, the rootId is the instanceId from the incoming json
    const createGraph = (rootItemId) => {
      if (!rootItemId) return null;

      const rootItemData = remainMap.get(rootItemId);
      let rootItem = createMap.get(rootItemId);
      if (!rootItemData) return rootItem; // node has already visited

      if (!rootItem) {
        rootItem = new ItemMap[rootItemData._type][rootItemData._key](
          _threekitApi,
          rootItemData
        );
        createMap.set(rootItemId, rootItem);
      }

      rootItem.fromJsonObj(rootItemData);
      remainMap.delete(rootItemId); // remove the item from map

      SIDE_KEYWORD.filter((side) => rootItemData.hasOwnProperty(side)).forEach(
        (side) => {
          rootItem[side] = createGraph(rootItemData[side]);
        }
      );

      return rootItem;
    };
    createGraph.bind(this);

    const remainMap = new Map(_items.map((item) => [item._id, item]));
    const createMap = new Map();

    const firstItemData = remainMap.get(_firstItem);
    const newFirstItem = new ItemMap[firstItemData._type][firstItemData._key](
      _threekitApi,
      firstItemData
    );

    createMap.set(firstItemData._id, newFirstItem);
    createGraph(firstItemData._id);
    this._firstItem = newFirstItem;
    this._items = [...createMap].map((pair) => pair[1]);
    this._id = _id;
    this._setLastItem();

    return this;
  };

  init = () => Promise.all(this._items.map((item) => item.init()));

  incrementItemRotation = async (item) => {
    if (!this._items.includes(item))
      throw new Error(
        'setItemRotation Error, item does not belongs to the island'
      );
    if (item._type === 'side')
      throw new Error(
        'Side are not allowed to rotate, its depend on the connection with seat'
      );

    const allConnections = item.getConnections();

    const validConnections = allConnections.filter(
      ({ target }) => target._type !== 'side'
    );
    const isAnytable = item._type === 'anytable';

    if (!validConnections.length) {
      if (isAnytable) {
        if (allConnections.length === 1) {
          // other connections are sides
          item._disconnect();
          item.setRotation(item.rotation + 90);
          allConnections.forEach(
            ({ alignment, localSide, target, targetLocalSide }) => {
              const nextConnector = {
                target,
                targetLocalSide,
                alignment,
                localSide: NEXT_SEAT_SIDES[localSide],
              };
              item._connectTo(nextConnector);
            }
          );
        }
      } else {
        // if no seat connection exist, just rotate the item and all connected sides, this case all connect sides will rotate together with seat
        item.setRotation(item.rotation + 90);
        allConnections.forEach(({ localSide, target, targetAlignment }) => {
          target._connectTo({
            target: item,
            targetLocalSide: localSide,
            alignment: targetAlignment,
          });
        });
      }
    } else {
      // this is the povit item which stays the same after this item rotation. The povit item must be a seat item
      // the logic here to make sure the same povit item will be select for continue rotate the same item
      // is to calculate the all edge rotation of this item in the world space and find the edge that has smallest world rotation that connect to a seat
      const seatConnectWithSideRotation = validConnections
        .map((connection) => ({
          ...connection,
          localSideRotation: item._getLocalSideWorldRotation(
            connection.localSide
          ),
        }))
        .sort((a, b) => a.localSideRotation - b.localSideRotation);

      const povit = seatConnectWithSideRotation[0];

      // disconnect item and reconnect the item to povit with the new local side
      item._disconnect();

      const rotateBack =
        item._type === 'seat' &&
        item._key !== 'wedge' &&
        item.rotation > 0 &&
        item.rotation <= 90;
      let localSide = getNextValidLocalSide(item, povit.localSide);
      if (rotateBack) {
        localSide = OPPOSITE_SEAT_SIDES[localSide];
      }

      item._connectTo({
        target: povit.target,
        targetLocalSide: povit.targetLocalSide,
        alignment: ALIGNMENT_MAP[povit.alignment],
        localSide,
      });

      const sideConvertionRequired =
        !isAnytable &&
        !(
          seatConnectWithSideRotation.length === 1 ||
          (seatConnectWithSideRotation.length === 2 &&
            seatConnectWithSideRotation[0].localSide !==
              OPPOSITE_SEAT_SIDES[seatConnectWithSideRotation[1].localSide])
        );

      await Promise.all(
        allConnections.map(
          async ({ target, targetLocalSide, alignment, localSide }) => {
            if (target === povit.target) return;

            let side = getNextValidLocalSide(item, localSide);
            if (rotateBack) {
              side = OPPOSITE_SEAT_SIDES[side];
            }
            const connector = {
              target: item,
              targetLocalSide: side,
              alignment,
              localSide: targetLocalSide,
            };

            if (
              target._type === 'seat' ||
              !sideConvertionRequired ||
              target.width ===
                item.getLocalSideDimension(connector.targetLocalSide)
            ) {
              this._connectRecersive(target, connector);
            } else {
              const deleteItemId = target.getInstanceId();
              const deleteItemKey = target._key;

              const key = swapSideKey(target._key);

              const { style } = target;

              this.deleteItem(target);
              const ProperSide = ItemMap.side[key];
              const sideInstance = new ProperSide(this._threekitApi);
              this.addItem(sideInstance, connector);
              await sideInstance.init();
              sideInstance.style = style;
              convertSideEvent(
                deleteItemId,
                deleteItemKey,
                sideInstance.getInstanceId(),
                sideInstance._key
              );
            }
          }
        )
      );
    }
  };

  intersectWithIsland = (targetIsland) => {
    for (const thisItem of this._items) {
      for (const targetItem of targetIsland.getItems()) {
        if (thisItem.intersectWith(targetItem)) {
          return true;
        }
      }
    }

    return false;
  };

  applyTranslation = (translation) => {
    this._items.forEach((item) => {
      const newTrans = { ...item.translation };
      newTrans.x += translation.x || 0;
      newTrans.z += translation.z || 0;
      item.setTranslation(newTrans);
    });
  };

  getBoundingBox = () =>
    this._items.reduce(
      (box, item) => box.union(item.getBoundingBox()),
      new this._threekitApi.THREE.Box3()
    );

  updatePosition = () => {
    const { min, max } = this.getBoundingBox();
    const position = {
      x: (min.x + max.x) / 2,
      y: (min.y + max.y) / 2,
      z: (min.z + max.z) / 2,
    };
    const seats = this.getSeatItems();

    const gravity = {
      x: 0,
      y: 0,
      z: 0,
    };
    ['x', 'y', 'z'].forEach((axis) => {
      let total = 0;
      let va = 0;
      for (const seat of seats) {
        const { translation } = seat;
        const v = translation[axis];
        total += v;
        va += v ** 2;
      }
      if (seats.length > 0) {
        gravity[axis] = total / seats.length;
      }
    });

    this.position = position;
    this.gravity = gravity;

    return { position, gravity };
  };
}
